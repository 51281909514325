<template>
  <v-dialog
    v-model="modalModel"
    content-class="elevation-0 mx-2"
    max-width="500"
    v-bind="$attrs"
    @click:outside="close"
    v-on="$listeners"
  >
    <v-card outlined tile elevation="0" class="pa-4 px-md-10">
      <v-card-title class="text-h5 px-0 flex-nowrap align-start">
        <span
          class=" info-modal__title text-body-2 text-md-h5 font-weight-regular font-weight-md-bold"
          :class="{ 'info-modal__title--mobile text-center': media.isMobile }"
        >
          {{ title }}
        </span>

        <v-spacer />

        <v-btn class="info-modal__close-button" icon @click="close">
          <v-icon size="20" color="primary">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="content" class="pa-0 text-body-1">
        {{ content }}
      </v-card-text>

      <v-card-actions class="mt-2 px-0">
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'InfoModal',

  inject: ['media'],

  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
    content: { type: String, default: '' },
  },

  computed: {
    modalModel: createModelWrapper('value', 'input'),
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.info-modal {
  &__title {
    word-break: normal;
    &--mobile {
      word-break: break-word;
    }
  }

  &__close-button {
    @media (max-width: map.get($--screens, 'sm')) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
